
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from "vue-class-component";
import { Etapa } from "@/core/models/gestao";
import { ModeloOrcamentoEtapaService } from '@/core/services/orcamentoObras';
import { ModeloOrcamentoEtapa } from '@/core/models/orcamentoObras';
 
 
@Component
export default class ListaEtapa extends mixins(Vue) {
  item = new ModeloOrcamentoEtapa();
  service = new ModeloOrcamentoEtapaService();

  expanded:any= []
  tree: any [] = []; 
  open: any[] = [];
  active: any[] = [];

  dialog: boolean = false;
  valid: boolean = true;
  
  titulo: string = "Etapa";
  subTitulo: string = "Lista das Etapas cadastradas para o uso no Sistema";

  Novo(item) {
    if(item) {
      this.item.modeloEtapaPaiId = item.id;
      this.dialog = true;
    } else {
      this.item = new ModeloOrcamentoEtapa();
      this.dialog = true;
    }
  }

  Listar(){ 
    this.service.TreeView().then(
      res => {
        this.tree = res.data.filter(x => x.modeloEtapaPaiId == null);    
      },
    );
  }  
 
  Excluir(item: Etapa) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res;
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
         this.Listar();
      }
    });
  }

  Editar(item: Etapa) {
    this.service.ObterPorId(item.id, 'Itens').then(
      res => {
        this.item = res.data;
        this.dialog = true;
      }
    )
  }

  isExpanded(item) {
    return this.open.includes(item.id);
  }

  // Alterna o estado de expansão
  expandEtapa(item) {
    const index = this.open.indexOf(item.id);
    if (index === -1) {
      // Se o item não estiver expandido, adiciona o id na lista
      this.open.push(item.id);
    } else {
      // Caso contrário, remove o id da lista
      this.open.splice(index, 1);
    }
  }

  mounted() {
    this.Listar();
  }
}
